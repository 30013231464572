<template>
    <div class="landing">
        <NJT_Title :landing="true" />
        <div class="bus-container">
            <button class="button bus" @click="gotoBus">BUS</button>
        </div>
        <div class="lightrail-container">
            <button class="button lightrail" @click="gotoLightRail">LIGHTRAIL</button>
        </div>
        <div class="rail-container">
            <button class="button rail" @click="gotoRail">RAIL</button>
        </div>
    </div>
</template>

<script>
    import NJT_Title from "@/components/NJT_Title";

    const __SELECTED_DEFAULT_PAGE_KEY__ = "__SELECTED_DEFAULT_PAGE_KEY__";
    
    export default {
        name: "Landing",
        components: {
            NJT_Title
        },
        mounted() {
            const selectedPage = localStorage.getItem(__SELECTED_DEFAULT_PAGE_KEY__);
            if(selectedPage){
                switch(selectedPage){
                    case "bus": 
                        this.gotoBus();
                        break;
                    case "lightrail":
                        this.gotoLightRail();
                        break;
                    case "rail":
                        this.gotoRail();
                        break;
                }
            }

            console.log("THE CURRENT URL", this.$route, window.location.href);

            // need to look for \/\?.*#\/, and then use that to redirect

            const pattern = /\/\?(.*)#\//;
            const match = window.location.href.match(pattern);

            console.log("CURRENT URL MATCH", match, match.length, match.input);

            if(match.length > 1){
                let query = match[1];

                // everything before the question mark?
                const question = match.input.indexOf("?");
                const base = match.input.substring(0, question);

                // and now redirect to rail trips?
                // OK, but there's this weird thing where it keeps this extra cruft
                console.log("CURRENT MATCH", query, base);

                if(query.indexOf("trainID") > -1) {
                    if(query.indexOf("station") < 0){
                        // no station here causes issues...
                        query += "&station=%20";
                    }

                    window.location.replace(base + "#/rail/stops?" + query);
                }
                else{
                    window.location.replace(base + "#/rail/trips?" + query);
                }

            }
        },
        methods: {
            gotoBus(){
                this.saveSelection("bus");
                this.$router.push({
                    path: '/bus/home'
                });
            },
            gotoLightRail(){
                this.saveSelection("lightrail");
                this.$router.push({
                    path: '/lightrail/home'
                });
            },
            gotoRail(){
                this.saveSelection("rail");
                this.$router.push({
                    path: '/rail/home'
                });
            },
            saveSelection(type){
                localStorage.setItem(__SELECTED_DEFAULT_PAGE_KEY__, type);
            }
        }
    }
</script>

<style scoped>
    .landing {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: calc(100 * var(--mvh));
        width: 100vw;
        overflow: hidden;

        --gradient: 1%;
    }

    .mobile .landing {
        height: fill-available;
        height: -webkit-fill-available;
    }

    .bus-container {
        --color: var(--bus-primary);
        width: 100%;
        display: flex;
        justify-content: center;
        background: linear-gradient(to right, var(--color) 0%, var(--color) var(--gradient), transparent var(--gradient), transparent 100%);
    }

    .lightrail-container {
        --color: var(--lightrail-primary);
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: var(--lightrail-primary);
        background: linear-gradient(to right, var(--color) 0%, var(--color) var(--gradient), transparent var(--gradient), transparent 100%);
    }

    .rail-container {
        --color: var(--rail-primary);
        width: 100%;
        display: flex;
        justify-content: center;
        background: linear-gradient(to right, var(--color) 0%, var(--color) var(--gradient), transparent var(--gradient), transparent 100%);
    }

    .mobile .bus-container,
    .mobile .lightrail-container,
    .mobile .rail-container {
        --gradient: 2%;
    }

    .button {
        margin: calc(7 * var(--mvh)) calc(5 * var(--mvh));
        padding: calc(4.5 * var(--mvh));
        width: 40vw;
        box-shadow: 0px 4px 2px -2px rgb(0 0 0 / 25%), 
            0px 3px 3px 0px rgb(0 0 0 / 19%), 
            0px 2px 5px 0px rgb(0 0 0 / 16%);
        font-size: 2rem;
        font-weight: bold;
        color: white;
        border-radius: calc(2 * var(--mvh));
        position: relative;
    }

    .mobile .button {
        width: 70vw;
    }

    .bus {
        background-color: var(--bus-primary);
    }

    .lightrail {
        background-color: var(--lightrail-primary);
    }

    .rail {
        background-color: var(--rail-primary);
    }

    .landing::before {
        content: "";
        /* background: url("../assets/njt_logo.svg"); */
        background: url("../assets/hug-icon-s.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: calc(35 * var(--mvh));
        height: calc(35 * var(--mvh));
        opacity: 0.02;
        top: calc(-1 * var(--mvh));
        left: 4vw;
        /* bottom: 0;
        right: 0; */
        position: fixed;
        z-index: -1;
        pointer-events: none;
    }

    .landing::after {
        content: "";
        /* background: url("../assets/njt_logo.svg"); */
        background: url("../assets/hug-icon-s.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: calc(100 * var(--mvh));
        height: calc(100 * var(--mvh));
        opacity: 0.02;
        top: calc(20 * var(--mvh));
        left: 45vw;
        /* bottom: 0;
        right: 0; */
        position: fixed;
        z-index: -2;
        pointer-events: none;
    }
</style>